import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=6bf17fd4&scoped=true&"
import script from "./TheHeader.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TheHeader.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./TheHeader.css?vue&type=style&index=0&id=6bf17fd4&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf17fd4",
  null
  
)

/* custom blocks */
import block0 from "./TheHeader.yml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc2938803066%2Fsrc%2Fexternal-tarificator%2Fsrc%2Fui%2Flayouts%2F_components%2FTheHeader%2FTheHeader.vue&lang=yml"
if (typeof block0 === 'function') block0(component)

export default component.exports